/* for app.bamboohr.com/login/ */

import './domain-login';
import './styles.styl';

function validateLoginDomain(input) {
	var domain = input.val();
	$.ajax({
		async: false,
		url: '/ajax/domain.php',
		type: 'GET',
		data: {
			test: domain
		},
		dataType: 'json',
		success: function (data) {
			if (data.taken === true) {
				var uri = '';
				var qs = window.BambooHR.Utils.queryStringToObject();
				if (qs.hasOwnProperty('r')) {
					// This will take all other GET params and url encode them together under `r` to be passed to login.php
					for (let key in qs) {
						// eslint-disable-next-line max-depth
						if (key !== 'r' && qs.hasOwnProperty(key)) {
							uri += (uri ? '&' : '?') + key + '=' + qs[key];
						}
					}
					uri = '?r=' + encodeURIComponent(qs.r) + encodeURIComponent(uri);
				}
				//redirect to the customer login page
				document.cookie = "domain=" + domain + "; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/";
				window.location.href = "https://" + domain + "." + window.Res.BASE_DOMAIN + "/login.php" + uri;
			} else {
				setMessage("That doesn't look like a valid BambooHR domain...", 'error');
			}
		},
		error: function() {
			setMessage("That doesn't look like a valid BambooHR domain...", 'error');
		}
	});
}
var message = {
	text: null,
	type: null
};

$(function() {
	$('#domain').focus();

	$("html:not(.ieOld) .forgot").click(function(e) {
		e.preventDefault();
		if ($(this).is(".fback")) {
			$('#domain').focus();
		}
	});

	if (message.text) {
		setMessage(message.text,message.type);
		message.text = null;
		message.type = null;
	}
	$('#submit').click(function(e) {
		e.preventDefault();
		validateLoginDomain($('#domain'));
	});
	$('.messageCloseWrapper').click(function(e) {
		e.preventDefault();
		closeMessage();
	});

});
