$(document)
	.on('click', '.js-what-is-my-domain', function(e) {
		$('.js-flip-container').toggleClass('flip');
	})
	.on('click', '.js-validate-domain', function() {
		var domainString = $('.js-domain-input').val();

		$.getJSON("/ajax/domain.php", { test: domainString })
			.done(function(data) {
				var url;

				if (data.taken == true) {
					url = "https://www." + domainString + ".bamboohr.com/payroll/login";
					window.location.href = url;
				}
			});
	});
